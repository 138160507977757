import React, {useState, useEffect, useRef } from 'react';
import axios from 'axios'; // for making HTTP requests
import '../App.css';
import './components.css';

import { initializeApp } from "firebase/app";
import { getDatabase,ref, set  } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAzNjR3uk6P0Uh086AL081-6dCAYZTl8KE",
    authDomain: "website-b87b1.firebaseapp.com",
    databaseURL: "https://website-b87b1-default-rtdb.firebaseio.com",
    projectId: "website-b87b1",
    storageBucket: "website-b87b1.appspot.com",
    messagingSenderId: "437986614495",
    appId: "1:437986614495:web:5edaacad1d98c3e2599d6a",
    measurementId: "G-8Q4VSKV3KT"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);


const CommingSoonButton: React.FC<{}> = ({}) => {
    const [email, setShowEmail] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null); // Specify the ref type

    useEffect(() => {
        // Function to handle click events on the document
        const handleClickOutside = (event: MouseEvent) => {
          if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
            setShowEmail(false);
          }
        };
    
        // Attach click event listener to the document
        document.addEventListener('click', handleClickOutside);
    
        // Cleanup function to remove the event listener when component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
    return (
        <div className='comingSoonContainer'> 
    
        <div 
            className='comingSoonButton'
            ref={elementRef}
            onClick={()=> {!subscribed ? setShowEmail(true): setShowEmail(false)}}>

            {!email && (subscribed ? 'Thanks for subscribing' : 'COMING SOON') } 
            {email && !subscribed && (<Email setSubscribed={setSubscribed} setShowEmail={setShowEmail}/>)}

        </div>
        <div className = {subscribed ? "thanksForSubscribingOut": "thanksForSubscribingIn" }
            style={{
                    color: '#aaaaaa',
                    fontFamily: 'Alphabet-light',
                    fontSize: 12,
                    fontWeight: '100',
                    paddingTop: 10,
            }}> sign up to gain exclusive content
        </div>
       </div>
    );
};

const Email: React.FC<{setSubscribed: any, setShowEmail:any,}> = ({setSubscribed, setShowEmail}) => {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);

    const validateEmail = (email:string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const writeUserData = (email:string, date:string) => {
        const db = getDatabase();
        const pattern = /[@.]/g;
        console.log("EMAIL", email)
        const cleanedEmail = email.replace(pattern, '');

        set(ref(db, 'users/' + cleanedEmail), {
          email: email,
          date: date,
        });
      }
    const submitEmail =  async () => {
        setShowEmail(false);
        setSubscribed(true);
        writeUserData(email, new Date().toDateString());
    };

    const handleKeyPress = (e:any) => {
        if (e.key === 'Enter' && isValid) {
            submitEmail();
        }
    };

    const handleInputChange = (e:any) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);
        setIsValid(validateEmail(inputEmail));
    };
    
    return (
        <div className='enterEmail'>
            <input 
            className='emailInput'
            type="text" 
            placeholder="Email"
            value={email}
            onKeyDown={handleKeyPress}
            onChange={handleInputChange}
            autoFocus
            >
            </input>
            {isValid && 
            <div onClick={submitEmail} className='submitButton'>
                <img src="MFKINGArrow.png" alt="the arrow"/>
            </div>  }
        </div>
    )
};


export default CommingSoonButton;

