import React, { useState ,useEffect} from 'react';
import './components.css';
// TypeScript interface for the component props
interface FlashlightMouseProps {
  width: string;
  height: string;
  overlayColor: string;
  flashlightSize: number;
  children: React.ReactNode;
}

const FlashlightMouse: React.FC<FlashlightMouseProps> = ({
  width,
  height,
  overlayColor,
  flashlightSize,
  children,
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [deviceType, setDeviceType] = useState('');
    const [isClicking, setIsClicking] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);
   
const move = (e: { clientX: number; clientY: number; }) => {
    const x =  e.clientX || 0;
    const y = e.clientY  || 0;
 
    setCursorX(x);
    setCursorY(y);
    
    // Set the cursor border's position directly
    const cursorBorder = document.getElementById('cursor-border');
    if (cursorBorder) {
        cursorBorder.style.left = `${x}px`;
        cursorBorder.style.top = `${y}px`;
    }
};
const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPosition({
        x: e.clientX,
        y: e.clientY,
    });
};

    useEffect(() => {
        document.addEventListener('mousemove', move);
        return () => {
          document.removeEventListener('mousemove', move);
        };
      }, []);
     

    return (
    <div
        style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        // overflow: 'hidden',
        }}
        onMouseMove={handleMouseMove}
    >
        <img style={{
            height: '100%',
            width:'100%',
            position:'absolute', 
            left:'50%', 
            top:'50%', 
            transform:'translate(-50%,-50%'}} 
            src='Background.png' 
            alt="background" />
        <img 
          className = 'shorts'
          src='shorts.png' 
          alt="shorts"/>
        <div
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: `radial-gradient(circle at ${position.x}px ${position.y}px, rgba(0, 0, 0, 0.4), #131518 150px)`,
            pointerEvents: 'none', // Ensures the div doesn't interfere with mouse events
        }}
        /> 
        <img style={{
            height: '100%', 
            width:'100%',
            position:'absolute', 
            left:'50%', 
            top:'50%', 
            transform:'translate(-50%,-50%'
            }} 
            src='GridOverlayOld.png' alt="grid" />
        <div
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: `radial-gradient(circle at ${position.x}px ${position.y}px, rgba(0, 0, 0, 0),rgba(19, 21, 24, 0.3) 100px)`,
            pointerEvents: 'none', // Ensures the div doesn't interfere with mouse events
        }}
        /> 
        {children}
    </div>
    );
};

export default FlashlightMouse;
