import React from 'react';
import Menu from './components/Menu';
import CommingSoonButton from './components/CommingSoonButton';
import TheoText from './components/TheoText';
import './App.css';


const ButtonOverlays: React.FC<{}> = ({  }) => {
    
    return (
        <>
      
        <img className="logo" src='TheoLogo.png' 
        alt="Image" />
        <Menu />
        <TheoText />
        <CommingSoonButton /> 
        </>
    );
};


export default ButtonOverlays;
