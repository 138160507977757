import React, { createContext, useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { P5CanvasInstance, ReactP5Wrapper, Sketch,   SketchProps} from "@p5-wrapper/react";
import ButtonOverlays from './ButtonOverlays';
import FlashlightMouse from './components/flishlightMouse';

function makeGrid (num_boxes_w: number = 12, windowWidth: number = 10, windowHeight: number = 10) {
  let gridPoints: [number,number][][] = [];
  let w = windowWidth/num_boxes_w;

  // times ten for padding when we shear and skew
  for (let i = -windowHeight*10/2; i < windowHeight*10/2+w; i+=w) {
    let row: [number,number][] = [] 
    for (let j = -windowWidth*10/2; j < windowWidth*10/2+w; j+=w) {
      row.push([j,i]);
    }
    gridPoints.push(row);
  }

  
  return skewGrid(gridPoints);
}

function skewGrid (grid: [number, number][][]) {
  const perspectiveAngle = Math.PI / 10; // Example angle for perspective

  const matrix:  [number[], number[], number[]] = [
    [1, -0.2, 0],
    [-0.2, 1, 0],
    [0, 0, 1]
  ];
  return grid.map(row => row.map(point => {
    const [x, y] = point;
    const newX = matrix[0][0] * x + matrix[0][1] * y + matrix[0][2];
    const newY = matrix[1][0] * x + matrix[1][1] * y + matrix[1][2];
    return [newX*1.5, newY];
  }));
}

function perspectiveGrid (grid: [number, number][][]) {
  const perspectiveAngle = Math.PI / 10; // Example angle for perspective
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid[i].length; j++) {
      const [x, y] = grid[i][j];
      const z = 0; // Example z value for perspective
      const distance = 100; // Example distance for perspective
      const perspective = distance / (z + distance);
      const newX = x * perspective;
      const newY = y * perspective;
      grid[i][j] = [newX, newY];
    }
  }
}

function drawGrid(p5: P5CanvasInstance<MySketchProps>, grid: number[][][]) {
  p5.stroke('#707174');
  for(let i = 0; i < grid.length-1; i++) {
    for(let j = 0; j < grid[i].length-1; j++) {
      p5.line( grid[i][j][0], grid[i][j][1], grid[i+1][j][0], grid[i+1][j][1]);
      p5.line( grid[i][j][0], grid[i][j][1], grid[i][j+1][0], grid[i][j+1][1]);
      // p5.line(p1[0], p1[1], p2[0], p2[1]);
    }
  }
  
}

interface MySketchProps extends SketchProps {
  windowSize: { width: number; height: number; };
}

function flashlightMouse(p5: P5CanvasInstance<MySketchProps>, width: number, height: number) {

  p5.noFill();
  for(var r = 0; r < width*3; r++) {
      p5.stroke(0,r/2);
      p5.ellipse(p5.mouseX-width/2, p5.mouseY-height/2, r, r);
  }
}





function sketch(p5: P5CanvasInstance<MySketchProps>) {
  let windowSize = { width: 0, height: 0 }
  p5.updateWithProps = (props: {windowSize: {width: number, height: number}}) => {
    if (props.windowSize) {
      windowSize = { width: props.windowSize.width, height: props.windowSize.height};
    }
  };
  let grid: number[][][] = [[]];

  p5.setup = () =>{ 
    
    p5.createCanvas(windowSize.width, windowSize.height, p5.WEBGL);
    p5.pixelDensity(1);
    grid = makeGrid(24, windowSize.width, windowSize.height);
    drawGrid(p5, grid);
  }
  p5.draw = () => {
  };

  return <div></div>;
}


function App() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
});

useEffect(() => {
    function handleResize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
}, []); 


  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background:'#131518'
      }}> 
    {/* <ReactP5Wrapper sketch={sketch} windowSize={windowSize}/> */}
    <FlashlightMouse width="100vw" height="100vh" overlayColor="rgba(0, 0, 0, 0.8)" flashlightSize={20}>
      <ButtonOverlays/>
    </FlashlightMouse>
    
    </div>
  );
}

export default App;
