import React, {useState, useEffect} from 'react';

import '../App.css';

const Menu: React.FC = () => {
    const [rotation, setRotation] = useState(0);
    const [showSocials, setShowSocials] = useState(false);
    const handleClick = () => {
        if (rotation === 0) {
            setRotation(180);
            setShowSocials(true);
        } else {
            setRotation(0);
            setShowSocials(false)
        } 
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            }}>
            <div 
            onClick = {handleClick}
            className='menu'
            style={{
                transform: `rotate(${rotation}deg)`,
            }}
            >
                {/* Your component code goes here */}
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="7" width="32" height="2" rx="1" fill="white"/>
                    <rect y="15" width="32" height="2" rx="1" fill="white"/>
                    <rect y="23" width="32" height="2" rx="1" fill="white"/>
                </svg>
            </div>
            <div  className="menu-icons"> 
               {/* TODO: Also have them slide out*/}
                <Email slideOut={showSocials}/>
                <Linkedin slideOut={showSocials}/>
                <Instagram slideOut={showSocials}/> 
                <Twitter slideOut={showSocials}/>  
            </div>)
        </div>
    );
};

// twitter, linkedin, instagram, email
const Twitter: React.FC<{slideOut:boolean}> = ({slideOut}) => {
    return (
        <div  className={slideOut ? 'socialsContainer' : 'socialsContainer slideOutFromRight'}
        onClick={()=>{ if (slideOut) window.open('https://twitter.com/theoHealth', '_blank')}}
        >
            <svg width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2893 21.6779L1 40H3.55975L18 24M21.5 17L35.673 1H38L22.5 18.5" stroke="white" strokeLinejoin="round"/>
            <path d="M10.9538 2.5L36.0421 38.5H28.1388L3.93859 2.5H10.9538Z" stroke="white" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};
const Instagram: React.FC<{slideOut:boolean}> = ({slideOut}) => {
    return (
        <div className={slideOut ? 'socialsContainer' : 'socialsContainer slideOutFromRight'}
        onClick={()=>{ if (slideOut) window.open('https://www.instagram.com/theo_health/', '_blank')}}>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34 9.03846V25.9615C34 30.4011 30.4011 34 25.9615 34H9.03846C4.59894 34 1 30.4011 1 25.9615V9.03846C1 4.59894 4.59894 1 9.03846 1H25.9615C28.3423 1 30.4813 2.03495 31.9532 3.67949" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 25.9615C18.0795 25.9615 18.6455 25.9033 19.1923 25.7923C23.0546 25.0083 25.9615 21.5936 25.9615 17.5C25.9615 12.8268 22.1732 9.03845 17.5 9.03845C12.8268 9.03845 9.03845 12.8268 9.03845 17.5C9.03845 18.3844 9.17414 19.2371 9.42582 20.0385" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="28.2179" cy="6.78215" r="2.19231" stroke="white" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};
const Linkedin: React.FC<{slideOut:boolean}> = ({slideOut}) => {
    return (
        <div className={slideOut ? 'socialsContainer' : 'socialsContainer slideOutFromRight'}
        onClick={()=>{ if (slideOut) window.open('https://www.linkedin.com/company/theo-health/about/', '_blank')}}
        >
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.03075 5.81416C0.846045 4.35103 1.40398 1.00001 5.18653 1C8.92676 0.999988 9.89643 3.83186 9.75791 5.81416C9.61938 7.79646 8.09558 10.3451 5.18653 10.4867C2.8593 10.6 1.44633 10.5339 1.03075 10.4867V32.5752H9.75791V10.4867H18.3465V12.469C19.1315 11.8083 20.9231 10.4867 24.0261 10.4867C27.9049 10.4867 30.5368 12.6106 31.6451 13.7434C34 16.1504 34 20.115 34 24.0796C34 27.2513 34 31.3481 34 33H24.9958L24.5802 20.115C24.3955 19.4543 23.5551 17.8496 21.6712 17.8496C19.7872 17.8496 18.6698 19.4543 18.3465 20.115L18.0695 32.292" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};
const Email: React.FC<{slideOut:boolean}> = ({slideOut}) => {
    const handleClick = (e:any) => {
        if (slideOut){
        e.preventDefault();
        const mailtoLink = `mailto:jodie@theohealth.com?subject=${encodeURIComponent("")}`;
        window.open(mailtoLink, '_blank');
        }
      };
    return (
        <div className={slideOut ? 'socialsContainer' : 'socialsContainer slideOutFromRight'}
        onClick={handleClick}>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34 6.31818C34 3.38103 31.6191 1 28.6818 1H6.31818C3.38103 1 1 3.38103 1 6.31818M34 6.31818V20.2273C34 23.0138 31.741 25.2727 28.9545 25.2727H17.6364H6.31818C3.38103 25.2727 1 22.8917 1 19.9545V6.31818M34 6.31818L17.6364 16.5455L1 6.31818" stroke="white" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};

export default Menu;
